
import { defineComponent, PropType } from 'vue'

import BaseInputCheckbox from '@/components/common/BaseInputCheckbox.vue'
import BaseInputRadio from '@/components/common/BaseInputRadio.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import BaseTextArea from '@/components/common/BaseTextArea.vue'
import { InputMode } from '@/components/common/constants/InputMode'
import { InputOption } from '@/components/common/interface/InputOption'
import { EcloudOrganizationDetailFormProps } from '@/components/iam/interface/EcloudOrganizationDetailFormProps'
import { LocaleOptions } from '@/models/common/LocaleOptions'

export default defineComponent({
  name: 'EcloudOrganizationDetailForm',
  components: {
    BaseInputCheckbox,
    BaseInputRadio,
    BaseInputText,
    BaseSingleSelect,
    BaseTextArea,
  },
  props: {
    formValue: {
      type: Object as PropType<EcloudOrganizationDetailFormProps>,
      required: true,
    },
    // https://github.com/enechain/esquare-frontend/pull/1501 で不使用とした
    readableReportTypeInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    eclearStatusInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
    enableStatusInputOptions: {
      type: Array as PropType<InputOption[]>,
      required: true,
    },
  },
  emits: ['form-input'],
  data(): {
    formInputEventName: 'form-input'
    maxLengthOfAbbreviation: string
  } {
    return {
      formInputEventName: 'form-input',
      maxLengthOfAbbreviation: 'max:10',
    }
  },
  computed: {
    localeOptions(): InputOption[] {
      return new LocaleOptions().options
    },
    readonly(): InputMode {
      return InputMode.READONLY
    },
  },
})
