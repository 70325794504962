import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-505b21f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ecloud-organization-detail-form" }
const _hoisted_2 = { class: "ecloud-organization-detail-form__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_input_radio = _resolveComponent("base-input-radio")!
  const _component_base_single_select = _resolveComponent("base-single-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.organizationNameJa').toString(),
        name: "organizationNameJa",
        value: _ctx.formValue.name.ja,
        mode: _ctx.readonly,
        help: _ctx.$t('iam.message.organizationNameHelpMessage').toString()
      }, null, 8, ["label", "value", "mode", "help"]),
      _createVNode(_component_base_input_text, {
        label: _ctx.$t('iam.label.organizationNameEn').toString(),
        name: "organizationNameEn",
        value: _ctx.formValue.name.en,
        mode: _ctx.readonly
      }, null, 8, ["label", "value", "mode"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_radio, {
        label: _ctx.$t('iam.label.eclearContruct').toString(),
        name: "eclearContruct",
        value: _ctx.formValue.isEClearContracted,
        options: _ctx.eclearStatusInputOptions,
        onInput: _cache[0] || (_cache[0] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              isEClearContracted: event,
            })
        )
      }, null, 8, ["label", "value", "options"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input_radio, {
        label: _ctx.$t('iam.label.esquareEnabled').toString(),
        name: "esquareEnabled",
        value: _ctx.formValue.isEsquareEnabled,
        options: _ctx.enableStatusInputOptions,
        onInput: _cache[1] || (_cache[1] = 
          event =>
            _ctx.$emit(_ctx.formInputEventName, {
              ..._ctx.formValue,
              isEsquareEnabled: event,
            })
        )
      }, null, 8, ["label", "value", "options"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_single_select, {
        class: "ecloud-organization-detail-form__locale",
        label: _ctx.$t('iam.label.language').toString(),
        name: "locale",
        value: _ctx.formValue.locale,
        mode: _ctx.readonly,
        options: _ctx.localeOptions,
        placeholder: _ctx.$t('iam.label.locale').toString(),
        "options-label": "label"
      }, null, 8, ["label", "value", "mode", "options", "placeholder"])
    ])
  ]))
}