import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4734283e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "default-broking-fee-of-organization-form" }
const _hoisted_2 = { class: "default-broking-fee-of-organization-form__header" }
const _hoisted_3 = { class: "default-broking-fee-of-organization-form__heading" }
const _hoisted_4 = { class: "default-broking-fee-of-organization-form__participant-summary" }
const _hoisted_5 = {
  key: 0,
  class: "default-broking-fee-of-organization-form__participant-summary-text"
}
const _hoisted_6 = {
  key: 1,
  class: "default-broking-fee-of-organization-form__participant-summary-text"
}
const _hoisted_7 = { class: "default-broking-fee-of-organization-form__form" }
const _hoisted_8 = { class: "default-broking-fee-of-organization-form__add-button" }
const _hoisted_9 = { class: "default-broking-fee-of-organization-form__form" }
const _hoisted_10 = { class: "default-broking-fee-of-organization-form__remove-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_single_select = _resolveComponent("base-single-select")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_base_input_text = _resolveComponent("base-input-text")!
  const _component_base_icon = _resolveComponent("base-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('iam.label.defaultBrokingFee')), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.updatedAt)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('iam.label.updatedAt')) + ": " + _toDisplayString(_ctx.updatedAt), 1))
          : _createCommentVNode("", true),
        (_ctx.lastUpdatedParticipantName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('iam.label.lastUpdatedParticipantName')) + ": " + _toDisplayString(_ctx.lastUpdatedParticipantName), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_base_single_select, {
        label: _ctx.$t('trading.label.productType').toString(),
        name: "productType",
        value: _ctx.selectedProductType,
        options: _ctx.filteredProductTypeInputOptions,
        placeholder: 
          _ctx.$t('common.message.selectPlaceholder', {
            name: _ctx.$t('trading.label.productType'),
          }).toString()
        ,
        onInput: _ctx.onChangeProductType
      }, null, 8, ["label", "value", "options", "placeholder", "onInput"]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_base_button, { onClick: _ctx.onClickAdd }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.label.add')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValues, (form) => {
        return (_openBlock(), _createElementBlock("div", {
          key: form.productType.value,
          class: "default-broking-fee-of-organization-form__column"
        }, [
          _createVNode(_component_base_input_text, {
            label: form.productType.label,
            name: form.productType.label,
            value: form.brokingFee,
            "value-type": "number",
            required: "",
            rules: "required|numeric|scale:5|between:-999.999,999.999",
            placeholder: 
            _ctx.$t('common.message.inputPlaceholder', {
              name: _ctx.$t('trading.label.brokingFee'),
            }).toString()
          ,
            onInput: 
            event =>
              _ctx.onInput({
                productType: form.productType,
                brokingFee: event,
              })
          
          }, null, 8, ["label", "name", "value", "placeholder", "onInput"]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_base_icon, {
              "icon-name": "times-circle",
              onClick: ($event: any) => (_ctx.onClickRemove(form.productType))
            }, null, 8, ["onClick"])
          ])
        ]))
      }), 128))
    ])
  ]))
}